import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import style from "./style.scss";
import cn from "classnames";

const data = {
  alert: {
    alertContent:
      "With the recent flooding in Europe, <a href='https://livewell.optum.com/cnt_alert_Flooding_in_Europe?accesstoken=9CE3CE60-7D8D-11EE-8A7F-CDB15810617E' target='_blank'>find resources for those directly and indirectly impacted here.</a>",
    alertDisplay: {
      display: true,
    },
  },
};

export default ({}) => {
  if (!data?.alert) {
    return <></>;
  }

  const alertContent = data?.alert?.alertContent;
  const alertDisplay = data?.alert?.alertDisplay?.display;
  return alertContent && alertDisplay ? (
    <div className={cn(style.alertBackground)}>
      <div className={cn(style.alertContainer)}>
        <div className={cn(style.alert)}>
          <div className={cn(style.text)}>
            {/* <Icon icon={"exclamation"} className={cn(style.exclamationIcon)} /> */}
            <p>
              <label
                dangerouslySetInnerHTML={{
                  __html: alertContent,
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
