import React, { useEffect, useRef, useState } from "react";
import ChatBot from "react-simple-chatbot";
import Form from "./Form";
import style from "./style.scss";
import cn from "classnames";
import Modal from "atom/Modal";
import { Heading } from "atom/Typography";
import CloseIcon from "assets/icons/x.svg";

const bubbleStyle = {
  borderRadius: 8,
  color: "var(--palette-dark-blue)",
  padding: "6px 12px",
  boxShadow: "none",
};

const userStyle = {
  backgroundColor: "var(--palette-sky)",
  fontFamily: "var(--font-family)",
  borderRadius: 8,
  color: "var(--palette-dark-blue)",
  padding: "6px 12px",
  boxShadow: "none",
  marginBottom: 12,
};

export const UserBubble = ({ children }) => (
  <div style={userStyle}>{children}</div>
);

const LineMessage = ({ children }) => (
  <div className={"lineMessage"}>
    <span>{children}</span>
  </div>
);

const ModalChat = ({ onClose, className, id }) => {
  const [data, setData] = useState();

  const steps = [
    {
      id: "start",
      component: <LineMessage>For emergency help, call 911</LineMessage>,
      asMessage: true,
      trigger: "start-0",
      delay: 0,
    },
    {
      id: "start-0",
      message: "Hello, welcome to chat.",
      trigger: "start-1",
      delay: 0,
    },
    {
      id: "start-1",
      message:
        "If you are seeking emotional support, please call us at the number listed on liveandworkwell.com",
      trigger: "start-2",
      delay: 0,
    },
    {
      id: "start-2",
      message:
        "Please tell us about yourself so we can connect you with the right person.",
      trigger: "chat-form",
      delay: 0,
    },
    {
      id: "chat-form",
      component: <Form {...{ setData }} />,
      asMessage: true,
      waitAction: true,
      delay: 10,
    },
    {
      id: "after1",
      message:
        "Great! We're connecting you with a live agent who can assist with this.",
      trigger: "after2",
    },
    {
      id: "after2",
      component: (
        <LineMessage>
          <strong>Jennifer L </strong> joined the chat
        </LineMessage>
      ),
      asMessage: true,
      trigger: "after3",
    },
    {
      id: "after3",
      component: (
        <LineMessage>
          Please note this chat session will
          <br />
          end after 10 minutes of inactivity
        </LineMessage>
      ),
      asMessage: true,
      trigger: "review",
    },
    {
      id: "review",
      message:
        "Just to review, generally speaking our conversations are confidential. There are a few exceptions to this rule. If you express thoughts or plans of harming yourself or others, if a child or vulnerable adult is at risk of abuse or neglect, or if a judge or legal authority requested the information.",
      trigger: "review-ans",
    },
    {
      id: "review-ans",
      component: <UserBubble>Got it</UserBubble>,
      // user: true,
      trigger: "welcome",
    },
    {
      id: "welcome",
      message: `Welcome to you, My name is Jennifer. Do you have any questions about the confidentiality policy?`,
      trigger: "welcome-ans",
    },
    {
      id: "welcome-ans",
      component: <UserBubble>no</UserBubble>,
      // user: true, //no
      trigger: "response",
    },
    {
      id: "response",
      message: "Great. How can I assist you today?",
      trigger: "response-ans",
    },
    {
      id: "response-ans",
      component: (
        <UserBubble>
          I am looking to schedule time with a counselor I am suffering from
          grief
        </UserBubble>
      ),
      // user: true, //I am looking to schedule time with a counselor I am suffering from grief
      trigger: "response1",
    },
    {
      id: "response1",
      message:
        "I can definitely assist with that. I know grief can be overwhelming at times, it you're interested, I'm an EWS Specialist, and I can offer brief in the moment support to you today. Would you like to talk to me more about what you've been experiencing?",
      trigger: "response-ans1",
    },
    {
      id: "response-ans1",
      component: (
        <UserBubble>no I would just like to setup an appointment</UserBubble>
      ),
      // user: true, //no I would just like to setup an appointment
      trigger: "response2",
    },
    {
      id: "response2",
      message:
        "Ok, we can certainly go ahead and get you set-up. You have 8, no-cost, EWS sessions available to work with a counselor in your state. I can assist in finding a counselor in your state and scheddule a complimentary, 10 minute, consultation to have within 2 days. Would you like to get set-up with that option?",
      trigger: "response-ans2",
    },
    {
      id: "response-ans2",
      component: (
        <UserBubble>yes I am available in the next couple of days</UserBubble>
      ),
      // user: true, //yes I am available in the next couple of days
      trigger: "response3",
    },
    {
      id: "response3",
      message:
        "Very good. Do you have a preference regarding gender or ethnicity? Is there a day of the week that just won't work for you? Is there a preferred time of day?",
      trigger: "response-ans3",
    },
    {
      id: "response-ans3",
      component: <UserBubble>No preference. How about 5/21?</UserBubble>,
      // user: true, //No preference. How about 5/21?
      trigger: "response4",
    },
    {
      id: "response4",
      message:
        "Yes, I found a 10-minute session for you on May 21st at 4:00 PM CDT with William Capton MA LPC. Does that work for you?",
      trigger: "response-ans4",
    },
    {
      id: "response-ans4",
      component: <UserBubble>Yes, that works.</UserBubble>,
      // user: true, //Yes, that works.
      trigger: "response5",
    },
    {
      id: "response5",
      message:
        "Great. You will receive an email confirming your consultation. Is there anything else I could assist with?",
      trigger: "response-ans5",
    },
    {
      id: "response-ans5",
      component: <UserBubble>No, that is all</UserBubble>,
      // user: true, //No, that is all
      trigger: "response6",
    },
    {
      id: "response6",
      message:
        "Thank you for reaching out! Please feel free to reach out again, we are available 24 hours a day, 7 days a week.",
      trigger: "", //end true enable close  `
    },
  ];

  const handleEnd = e => {
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      const modalElement = modalRef.current.querySelector(".rsc-content");
      const handleScroll = event => {
        const { scrollTop, scrollHeight, clientHeight } = modalElement;
        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && event.deltaY < 0) event.preventDefault();

        // prevent scroll down, but allow scroll up
        if (scrollTop + clientHeight >= scrollHeight && event.deltaY > 0)
          event.preventDefault();
      };

      const handleTouchMove = event => {
        if (!event.cancelable) return;
        const { scrollTop, scrollHeight, clientHeight } = modalElement;
        const touch = event.touches[0];

        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && touch.clientY > touchStartY)
          event.preventDefault();

        // prevent scroll down, but allow scroll up
        if (
          scrollTop + clientHeight >= scrollHeight &&
          touch.clientY < touchStartY
        )
          event.preventDefault();
      };

      const handleTouchStart = event => {
        touchStartY = event.touches[0].clientY;
      };

      let touchStartY = 0;

      // get the modal element inside ref.current, classname is "rsc-content"
      modalElement.addEventListener("wheel", handleScroll);
      modalElement.addEventListener("touchstart", handleTouchStart);
      modalElement.addEventListener("touchmove", handleTouchMove);

      // handle browser deprecated event causes scroll issue
      const observer = patchOnScrollIssue(modalElement);
      return () => {
        modalElement.removeEventListener("wheel", handleScroll);
        modalElement.removeEventListener("touchstart", handleTouchStart);
        modalElement.removeEventListener("touchmove", handleTouchMove);

        // handle browser deprecated event causes scroll issue
        if (observer) {
          observer.disconnect();
        }
      };
    }
  }, [modalRef.current]);

  return (
    <Modal
      id={"chatcta"}
      finalClose={onClose}
      containerClass={cn(style.modal)}
      contentClass={cn(style.content)}
      isCloseButton={false}
      withClose={false}
    >
      <div className={cn(style.top)}>
        <Heading type="h5">Chat</Heading>
        <div className={cn(style.right)}>
          <CloseIcon onClick={() => onClose()} />
        </div>
      </div>
      <div
        ref={modalRef}
        style={{
          height: "100%",
          overflowY: "auto",
          padding: "1px 0px",
          boxSizing: "border-box",
        }}
      >
        <ChatBot
          handleEnd={e => handleEnd(e)}
          hideHeader={true}
          hideBotAvatar={true}
          hideUserAvatar={true}
          enableSmoothScroll={true}
          userDelay={100}
          botDelay={1200}
          bubbleStyle={bubbleStyle}
          steps={steps}
          cache={true}
          cacheName={"ctachat"}
          className={cn(style.chatbot, className)}
        />
      </div>
    </Modal>
  );
};

export default ModalChat;

const patchOnScrollIssue = modalElement => {
  // Callback function to handle mutations
  const mutationCallback = mutationsList => {
    for (let mutation of mutationsList) {
      if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
        modalElement.scrollTop = modalElement.scrollHeight;
      }
    }
  };

  // Create a MutationObserver instance and pass the callback function
  const observer = new MutationObserver(mutationCallback);
  if (modalElement) {
    observer.observe(modalElement, {
      childList: true,
      subtree: true,
      characterData: true,
    });
  }

  // Cleanup function to disconnect the observer when the component unmounts
  return observer;
};
