import React, { useEffect, useState } from "react";
import Button from "atom/Button";
import Input, { Select } from "atom/Input";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const fields = [
  {
    type: "select",
    id: "topic",
    optionDisabled: "Select a topic",
    label: "Chat topic",
    options: [
      {
        title: "Benefits and authorizations",
        value: "Benefits and authorizations",
      },
      {
        title: "Something else",
        value: "Something else",
      },
    ],
  },
  {
    type: "text",
    id: "firstname",
    placeholder: "",
    label: "First name",
  },
  {
    type: "text",
    id: "lastname",
    placeholder: "",
    label: "Last name",
  },
  {
    type: "date",
    id: "birthdate",
    placeholder: "",
    label: "Date of birth",
    format: "mm/dd/yyyy",
  },
  {
    type: "email",
    id: "email",
    placeholder: "",
    label: "Email address",
    format: "name@email.com",
  },
  {
    type: "select",
    id: "state",
    optionDisabled: "Select a state",
    label: "State",
    options: [
      {
        title: "Alabama",
        value: "Alabama",
      },
      {
        title: "Alaska",
        value: "Alaska",
      },
      {
        title: "Arizona",
        value: "Arizona",
      },
      {
        title: "Arkansas",
        value: "Arkansas",
      },
      {
        title: "California",
        value: "California",
      },
      {
        title: "Colorado",
        value: "Colorado",
      },
      {
        title: "Connecticut",
        value: "Connecticut",
      },
      {
        title: "Delaware",
        value: "Delaware",
      },
      {
        title: "District Of Columbia",
        value: "District Of Columbia",
      },
      {
        title: "Florida",
        value: "Florida",
      },
      {
        title: "Georgia",
        value: "Georgia",
      },
      {
        title: "Hawaii",
        value: "Hawaii",
      },
      {
        title: "Idaho",
        value: "Idaho",
      },
      {
        title: "Illinois",
        value: "Illinois",
      },
      {
        title: "Indiana",
        value: "Indiana",
      },
      {
        title: "Iowa",
        value: "Iowa",
      },
      {
        title: "Kansas",
        value: "Kansas",
      },
      {
        title: "Kentucky",
        value: "Kentucky",
      },
      {
        title: "Louisiana",
        value: "Louisiana",
      },
      {
        title: "Maine",
        value: "Maine",
      },
      {
        title: "Maryland",
        value: "Maryland",
      },
      {
        title: "Massachusetts",
        value: "Massachusetts",
      },
      {
        title: "Michigan",
        value: "Michigan",
      },
      {
        title: "Minnesota",
        value: "Minnesota",
      },
      {
        title: "Mississippi",
        value: "Mississippi",
      },
      {
        title: "Missouri",
        value: "Missouri",
      },
      {
        title: "Montana",
        value: "Montana",
      },
      {
        title: "Nebraska",
        value: "Nebraska",
      },
      {
        title: "Nevada",
        value: "Nevada",
      },
      {
        title: "New Hampshire",
        value: "New Hampshire",
      },
      {
        title: "New Jersey",
        value: "New Jersey",
      },
      {
        title: "New Mexico",
        value: "New Mexico",
      },
      {
        title: "New York",
        value: "New York",
      },
      {
        title: "North Carolina",
        value: "North Carolina",
      },
      {
        title: "North Dakota",
        value: "North Dakota",
      },
      {
        title: "Ohio",
        value: "Ohio",
      },
      {
        title: "Oklahoma",
        value: "Oklahoma",
      },
      {
        title: "Oregon",
        value: "Oregon",
      },
      {
        title: "Pennsylvania",
        value: "Pennsylvania",
      },
      {
        title: "Rhode Island",
        value: "Rhode Island",
      },
      {
        title: "South Carolina",
        value: "South Carolina",
      },
      {
        title: "South Dakota",
        value: "South Dakota",
      },
      {
        title: "Tennessee",
        value: "Tennessee",
      },
      {
        title: "Texas",
        value: "Texas",
      },
      {
        title: "Utah",
        value: "Utah",
      },
      {
        title: "Vermont",
        value: "Vermont",
      },
      {
        title: "Virginia",
        value: "Virginia",
      },
      {
        title: "Washington",
        value: "Washington",
      },
      {
        title: "West Virginia",
        value: "West Virginia",
      },
      {
        title: "Wisconsin",
        value: "Wisconsin",
      },
      {
        title: "Wyoming",
        value: "Wyoming",
      },
    ],
  },
];

export default props => {
  const { setData, triggerNextStep } = props;
  const [submitted, setSubmitted] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [formInput, setFormInput] = useState({
    topic: "Benefits and authorizations",
    firstname: "Anita",
    lastname: "Person",
    birthdate: "04/11/1986",
    email: "aperson@gmail.com",
    state: "Minnesota",
  });

  useEffect(() => {
    if (formInput && Object.entries(formInput).length == 6) setDisabled(false);
  }, [formInput]);

  useEffect(() => {
    // let initial = []
    // initial["topic"] =
  }, []);

  const handleChange = (e, name) => {
    setFormInput(prevState => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    setDisabled(true);
    setData(formInput);
    triggerNextStep({ value: formInput, trigger: "after1" });
  };

  return submitted ? (
    <div className={cn(style.chatAnswers)}>
      <Heading type="h5">Tell us more about you</Heading>
      <div className={cn(style.item)}>
        <label>Topic</label>
        <p>{formInput["topic"]}</p>
      </div>
      <div className={cn(style.item)}>
        <label>First name</label>
        <p>{formInput["firstname"]}</p>
      </div>
      <div className={cn(style.item)}>
        <label>Last name</label>
        <p>{formInput["lastname"]}</p>
      </div>
      <div className={cn(style.item)}>
        <label>Date of birth</label>
        <p>{formInput["birthdate"]}</p>
      </div>
      <div className={cn(style.item)}>
        <label>Email</label>
        <p>{formInput["email"]}</p>
      </div>
      <div className={cn(style.item)}>
        <label>State</label>
        <p>{formInput["state"]}</p>
      </div>
    </div>
  ) : (
    <div className={cn(style.chatForm)}>
      <Heading type="h5">Tell us more about you</Heading>
      <p>All fields are required.</p>
      {fields.map((val, i) => {
        const { id, type, label, format, placeholder, options } = val;

        return (
          <div className={cn(style.field)} key={i}>
            {type == "select" ? (
              <>
                <label htmlFor={id}>{label}</label>
                <Select
                  optionDisabled={placeholder}
                  items={options}
                  selected={formInput?.[id] || ""}
                  handleChange={e => handleChange(e, id)}
                  ariaLabel={id}
                  // required
                />
              </>
            ) : (
              <>
                <label htmlFor={id}>{label}</label>
                <Input
                  className={cn(style.input)}
                  {...{ id, type, placeholder }}
                  value={formInput?.[id] || ""}
                  onChange={e => handleChange(e, id)}
                  aria-describedby={`${id}_err`}
                  // validation={validation}
                  // onKeyDown={handleKeyDown}
                  required
                />
                <div className={cn(style.format)}>{format}</div>
              </>
            )}
          </div>
        );
      })}
      <Button text="Start chat" onClick={handleSubmit} disabled={disabled} />
    </div>
  );
};
