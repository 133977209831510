import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue }) => {
  const handleContinue = () => {
    onContinue();
  };

  return (
    <div className={cn(style.s1)}>
      <div className={cn(style.buttonsContainer)}>
        <div className={cn(style.formTitle)}>Returning member</div>
        <div className={cn(style.buttonBox)} onClick={handleContinue}>
          <div className={cn(style.formSubtitle)}>
            Welcome back! Let’s get you started.
          </div>
          <div className={cn(style.formText)}>
            Sign into your account and follow the steps in the “Find Care” tab
          </div>
        </div>
      </div>
      {/* <hr className={cn(style.divider)} /> */}
      <div className={cn(style.buttonsContainer)}>
        <div className={cn(style.formTitle)}>New member</div>
        <div className={cn(style.buttonBox)} onClick={handleContinue}>
          <div className={cn(style.formSubtitle)}>
          I have an access code
          </div>
          <div className={cn(style.formText)}>
            You received a Company Access Code from your employer
          </div>
        </div>
      </div>
    </div>
  );
};
