import React, { useState, useEffect } from "react";

import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";
import { generateNext7Days } from "lib/utils";

const STEPS = [
  {
    title: "Phone Call",
    text:
      "Your coach will call you at the phone number you provided at the time of your appointment.",
  },
  {
    title: "Video Session",
    text:
      "You can join your video session located in AbleTo’s mobile app or website up to 10 minutes ahead of session",
  },
];

export default ({ onContinue }) => {
  const [allTimes, setAllTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const handleSelect = key => {
    // onContinue();
    if (selectedTime === key) {
      setSelectedTime("");
    } else {
      setSelectedTime(key);
    }
  };

  useEffect(() => {
    // dayName, date, slots
    setAllTimes(generateNext7Days());
  }, []);

  return (
    <div className={cn(style.s9)}>
      <div>All times in ET </div>
      <div className={cn(style.timesContainer)}>
        {allTimes?.map((a, i) => {
          const { dayName, date, slots } = a;

          return (
            <div key={dayName} className={cn(style.dayContainer)}>
              <div className={cn(style.dayHeader)}>
                <div className={cn(style.dayName)}>{dayName}</div>
                <div className={cn(style.date)}>{date}</div>
              </div>
              <div className={cn(style.slotsContainer)}>
                {slots?.map((slot, j) => {
                  const key = `${date}_${slot}`;
                  const isSelected = selectedTime === key;
                  return (
                    <div
                      className={cn(
                        style.slot,
                        isSelected ? style.selected : ""
                      )}
                      onClick={() => handleSelect(key)}
                      key={key}
                    >
                      {slot}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className={style.noneWork} onClick={onContinue}>
        None of these times work for me
      </div>
    </div>
  );
};
