import React, { useEffect, createRef, useState } from "react";
import Button from "atom/Button";
import { useInView } from "react-intersection-observer";
import ProgressiveImage from "atom/ProgressiveImage";
import { Heading, LabelwithEmphasis } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";
import { motion, useScroll, useTransform } from "framer-motion";

const Card = ({ items, onClick }) => {
  const [active, setActive] = useState(0);

  const refs = [];

  const newRef = () => {
    const ref = createRef();

    refs.push(ref);

    return ref;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            setActive(entry.target.id);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    refs.forEach(ref => observer.observe(ref.current));

    return () => {
      refs.forEach(ref => ref.current && observer.unobserve(ref.current));
    };
  }, []);

  return (
    <div className={cn(style.cards)}>
      <div className={cn(style.left)}>
        <div className={cn(style.img)}>
          {items.map(({ image }, a) => (
            <img
              {...image}
              key={a}
              className={cn(style.inactive, active == a ? style.active : null)}
            />
          ))}
        </div>
      </div>
      <div className={cn(style.right)}>
        {items.map(({ category, title, content, button }, i) => (
          <div className={cn(style.text)} key={i} id={i} ref={newRef()}>
            <div className={cn(style.upper)}>
              {category && <label>{category}</label>}
              <Heading type={"h3"} className={cn(style.header)}>
                {title}
              </Heading>
              <LabelwithEmphasis className={cn(style.body)}>
                {content}
              </LabelwithEmphasis>
            </div>
            <Button onClick={() => onClick(category)} light>
              {button}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
