import React from "react";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";
import Icon from "atom/Icon";
import Button from "atom/Button";
import { LinkButton } from "atom/Anchor";
import Modal from "atom/Modal";

export default ({ onClick, onClose, data = modalOptions }) => {
  return (
    <Modal id={data?.id} contentClass={cn(style.content)} {...{ onClose }}>
      <div className={cn(style.container)}>
        <Heading type={"h3"}>{data?.title}</Heading>
        <p dangerouslySetInnerHTML={{ __html: data?.description }} />
        <div className={cn(style.option)}>
          {data?.options.map(
            ({ icon, title, subtext, buttonText, link, onClick }, i) => {
              return (
                <div key={i} className={cn(style.item)}>
                  <div className={cn(style.text)}>
                    <Heading type={"h4"}>{title}</Heading>
                    <p>{subtext}</p>

                    {onClick ? (
                      <Button
                        light
                        text="Explore"
                        onClick={() => onClick(link)}
                      />
                    ) : (
                      <LinkButton
                        className={cn(style.button)}
                        tabIndex={0}
                        to={link}
                        label={`${buttonText}`}
                        aria-label={`${buttonText}`}
                      />
                    )}
                  </div>
                  <Icon icon={icon} />
                </div>
              );
            }
          )}
        </div>
      </div>
    </Modal>
  );
};
