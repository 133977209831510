import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageCenter from "atom/PageCenter";
import Arrow from "assets/icons/down-arrow.svg";
import OptumLogo from "assets/optum-logo.svg";
import Anchor, { LinkButton } from "atom/Anchor";
import { Heading } from "atom/Typography";
import Button from "atom/Button";
import Input from "atom/Input";
import Icon from "atom/Icon";
import cn from "classnames";
import style from "./style.scss";

//provider - company name

export default () => {
  let navigate = useNavigate();
  const [company, setCompany] = useState("Optum");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const options = [
    {
      icon: "heart",
      title: "Emotional Wellbeing Solutions",
      subtitle:
        "This employee assistance program provides services to you and your household, at no cost to you.",
      body: [
        "Get short-term, no-cost counseling with a therapist",
        "Speak with an emotional support professional, 24/7",
        "Connect to financial, legal and community resources",
        "Find support for stress, anxiety, depression and more",
      ],
      linkText: "Go to Emotional Wellbeing Solutions",
      linkURL: () => navigate("/"),
    },
    {
      icon: "brain",
      title: "Behavioral health benefits through",
      subtitle: `If you’re enrolled in a health insurance plan through ${company}, you have a wide range of support.`,
      body: [
        "Find in-network therapists, psychiatrists and treatment centers",
        "Learn what your behavioral health benefits cover",
        "Manage your claims",
        "Find support for anxiety, depression, substance use, trauma, autism and more",
      ],
      linkText: "Continue to Live and Work Well  >",
      linkURL: () => window.open('https://www.liveandworkwell.com/en/member/veba'),
    },
  ];

  return (
    <PageCenter rootClass={cn(style.root)} contentClass={cn(style.content)}>
      <OptumLogo />
      <Heading type="h4" className={cn(style.message)}>
        {"Company welcome message"}
      </Heading>
      <Heading type="h2">{"Where would you like to start?"}</Heading>
      <div className={cn(style.options)}>
        {options.map(
          ({ icon, title, subtitle, body, linkText, linkURL }, i) => (
            <div key={i} className={cn(style.option)}>
              <div className={cn(style.top)}>
                <div className={cn(style.title)}>
                  <Icon icon={icon} />
                  {title} {i == 1 && company}
                </div>
                <strong>{subtitle}</strong>
                <ul>
                  {body.map((value, a) => (
                    <li key={a}>{value}</li>
                  ))}
                </ul>
              </div>
              <div className={cn(style.bottom)}>
                <div className={cn(style.divider)} />
                <LinkButton
                  className={cn(style.open)}
                  onClick={linkURL}
                  TagName="div"
                >
                  {linkText} {i == 0 && <Icon icon={"openlink"} />}
                </LinkButton>
              </div>
            </div>
          )
        )}
      </div>
      <div className={cn(style.help)}>
        <label>Do you need help now? </label> Talk to an Emotional Wellbeing
        Specialist anytime, 24/7. Call
        <Anchor href={null}> 1-555-555-5555</Anchor>
      </div>
    </PageCenter>
  );
};
