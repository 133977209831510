import React, { useContext, useEffect } from "react";
import cn from "classnames";
import _ from 'lodash'
import { useParams } from "react-router-dom";

// styles
import style from "./style.scss";
import companyLogo from "assets/images/company-logo.png";
import optumLogo from "assets/images/Optum_Logo_ora_RGB.png";
import Image from "atom/Image";
import { ThemeContext } from "provider/ThemeProvider";

export default ({ containerStyle, boxStyle, children, isFooter = true }) => {
  const { data, setData, logo } = useContext(ThemeContext);
  const { brand } = useParams();

  useEffect(() => {
    // prevent overriding existing content
    setData(prev => {
      const _v = _.cloneDeep(prev);
      _.set(_v, "content.brand", brand);
      return _v;
    });

    return () => {
      setData(prev => {
        const _v = _.cloneDeep(prev);
        _.set(_v, "content.brand", "default");
        return _v;
      });
    };
  }, [brand]);

  return (
    <div className={cn([style.login_wrapper, containerStyle])}>
      <div className={cn([style.login_box, boxStyle])}>
        <div className={cn(style.logo)}>
          <Image src={logo} />
        </div>
        {children}
      </div>
    </div>
  );
};
