import NicknameProvider from "provider/NicknameProvider";
import _ from "lodash";

export const LINKTYPES = {
  EXTERNAL: "EXTERNAL",
  ABSOLUTE: "ABSOLUTE",
  RELATIVE: "RELATIVE",
  PDF: "EXTERNAL",
  MAIL: "EXTERNAL",
  PHONE: "EXTERNAL",
};

const preventScrollListener = e => {
  // e.stopPropagation();
};

export const lockBody = isUnLock => {
  if (isUnLock) {
    // op1
    document.body.style.overflow = "hidden";
    const currentScroll = document.documentElement.scrollTop;
    window.scroll(0, 0);
    const root = document.getElementById("root");
    root.style.height = "100vh";
    root.style.width = "100vw";
    root.style.overflow = "auto";
    root.scrollTop = currentScroll;
    root.addEventListener("wheel", preventScrollListener);
    root.addEventListener("mousewheel", preventScrollListener);
    root.addEventListener("DOMMouseScroll", preventScrollListener);

    // op2
    // const targetElement = ref.current
    // disableBodyScroll(targetElement);
  } else {
    // op2
    // const targetElement = ref.current
    // enableBodyScroll(targetElement);

    // // op1
    document.body.style.overflow = "auto";
    document.body.style.position = null;
    const root = document.getElementById("root");
    const currentScroll = root.scrollTop;
    root.style.height = null;
    root.style.width = null;
    root.style.overflow = null;
    root.scrollTop = 0;
    window.scroll(0, currentScroll);

    root.removeEventListener("wheel", preventScrollListener);
    root.removeEventListener("mousewheel", preventScrollListener);
    root.removeEventListener("DOMMouseScroll", preventScrollListener);
  }
};

export const logout = callback => {
  localStorage.clear();
  NicknameProvider.removeNickname();
  callback();
};

export const getLinktype = link => {
  const reg = new RegExp("^(?:[a-z]+:)?//", "i");

  if (link?.toLowerCase().lastIndexOf(".pdf") != -1) {
    return LINKTYPES.PDF;
  }

  if (link?.toLowerCase().indexOf("mailto:") === 0) {
    return LINKTYPES.MAIL;
  }

  if (link?.toLowerCase().indexOf("tel:") === 0) {
    return LINKTYPES.PHONE;
  } else if (reg.test(link)) {
    return LINKTYPES.EXTERNAL;
  } else if (link?.indexOf("/") === 0) {
    return LINKTYPES.ABSOLUTE;
  } else {
    return LINKTYPES.RELATIVE;
  }
};

export const generateNext7Days = () => {
  // Define time slots parameters
  const startTime = 8; // Starting hour in 24-hour format
  const endTime = 15; // Ending hour in 24-hour format
  const timeInterval = 30; // Interval in minutes

  // Generate slots for each day based on the timeInterval
  const generateSlots = () => {
    const slots = [];
    const totalIntervals = (endTime - startTime) * (60 / timeInterval);

    for (let i = 0; i <= totalIntervals; i++) {
      const currentTime = startTime * 60 + i * timeInterval; // Calculate minutes from start time
      const hour = Math.floor(currentTime / 60);
      const minutes = currentTime % 60;
      const amPm = hour < 12 ? "am" : "pm";
      const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;

      // Format time string as "h:mmam/pm"
      const timeString = `${displayHour}:${_.padStart(minutes, 2, "0")}${amPm}`;
      slots.push(timeString);
    }

    return slots;
  };

  // Helper function to get formatted date and day name
  const getFormattedDate = date => {
    const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dayName = dayNames[date.getDay()];
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
    return { dayName, date: formattedDate };
  };

  // Generate next 7 days with slots
  const daysArray = _.times(7, index => {
    const date = new Date();
    date.setDate(date.getDate() + index);

    const { dayName, date: formattedDate } = getFormattedDate(date);
    return {
      dayName,
      date: formattedDate,
      slots: generateSlots(),
    };
  });

  return daysArray;
};
export const extractPhone = (mix) => {
  // let numb = mix.match(/\d/g);
  let numb = mix.match(/\+|\d/g);
  
  numb = numb?.join("") || "";
  return numb;
};