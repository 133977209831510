import React, { useRef, useEffect, useState } from "react";
import Arrow from "assets/icons/down-arrow.svg";
import style from "./style.scss";
import cn from "classnames";

const ICON_OFFSET = 20

export default ({
  className,
  id,
  value,
  values = [],
  onChange,
  onFocus,
  ...rest
}) => {
  const ref = useRef(null);

  const [width, setWidth] = useState('auto')
  useEffect(() => {
    if (values) {
      const countryName = values.find(a => a.value === value)?.label

      // calculate width
      const tempSpan = document.createElement("span");
      tempSpan.width = "auto";
      tempSpan.style['fontSize'] = "14px";
      tempSpan.innerHTML = countryName;
      document.body.append(tempSpan);
      setWidth(`${(tempSpan.offsetWidth + ICON_OFFSET) * 1}px`)
      document.body.removeChild(tempSpan);
    }
  }, [values, value]);

  return (
    <div className={cn(style.container, className)}>
      <select
        name={id}
        style={{ width }}
        {...{ id, value, onChange, onFocus }}
        {...rest}
        ref={ref}
      >
        {values.map(({ value, label }, i) => (
          <option key={value + i} value={value}>
            {label}
          </option>
        ))}
      </select>

      <Arrow />
    </div>
  );
};
