import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "atom/Button";
import Journey from "assets/images/journey.svg";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import { logout } from "lib/utils";
import Footer from "molecule/Footer";
import Header from "molecule/Header";
import Chatbot from "organism/Chatbot";
import Settings from "organism/Settings";
import Tutorial from "organism/Tutorial";
import NicknameProvider from "provider/NicknameProvider";
import SettingsProvider from "provider/SettingsProvider";
import BenefitsandPrograms from "screen/BenefitsandPrograms";
import AccessCodeProvider from "provider/AccessCodeProvider";
import { ThemeContext } from "provider/ThemeProvider";

import Benefits from "./Benefits";
import HealthJourneys from "./HealthJourneys";
import Spotlight from "./Spotlight";
import FAQs from "./FAQs";
import ImportantCommunications from "./ImportantCommunications";
import style from "./style.scss";
import cn from "classnames";
import CTA from "./CTA";
import Articles from "screen/Articles";
import Login from "molecule/Login";
import Register from "molecule/Register";
import Modal from "atom/Modal";

import ModalGethelp from "molecule/ModalGethelp";
import ModalChat from "organism/ModalChat";
import ModalSchedule from "organism/ModalSchedule";
import ModalCreateFreeAccount from "molecule/ModalCreateFreeAccount";

import Bot from "organism/Chatbot/Bot";
import ModalOptions from "./ModalOptions";

// schedule options before pop schedule
const scheduleOptions = (detailLeft, detailRight) => ({
  id: "schedule_options",
  title: "Choose the path to wellbeing that suits you",
  options: [
    {
      icon: "brain",
      title: "Schedule a no-cost virtual coaching session",
      subtext:
        "Share your goals and challenges, and we’ll provide your covered options.",
      buttonText: "Explore",
      onClick: typeof detailLeft === 'function' ? detailLeft: undefined,
      link: typeof detailLeft === 'string' ? detailLeft: undefined,
    },
    {
      icon: "chat",
      title: "Schedule a no-cost counseling session",
      subtext:
        "You deserve to feel better. We can help you find the right resources.",
      buttonText: "Explore",
      onClick: typeof detailRight === 'function' ? detailRight: undefined,
      link: typeof detailRight === 'string' ? detailRight: undefined,
    },
  ],
});

// chat options before pop chat
const chatOptions = (fnl, fnr) => ({
  id: "chat_options",
  title: "Choose the path to wellbeing that suits you",
  options: [
    {
      icon: "brain",
      title: "Get navigated guidance with our digital assistant",
      subtext:
        "Share your goals and challenges, and we’ll provide your covered options.",
      buttonText: "Explore",
      onClick: fnl,
    },
    {
      icon: "convo",
      title: "Chat with a specialist",
      subtext:
        "You deserve to feel better. We can help you find the right resources.",
      buttonText: "Explore",
      onClick: fnr,
    },
  ],
});

const Page = () => {
  let location = useLocation();
  const { data } = useContext(ThemeContext);
  
  let { article } = useParams();
  const [mode, setMode] = useState(null);
  const [filter, setFilter] = useState("All");
  const [showChatbot, setShowChatbot] = useState(null);
  const [openBenefits, setOpenBenefits] = useState(false);
  const [openArticles, setOpenArticles] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openCall, setOpenCall] = useState(false);
  const [openChatOptions, setOpenChatOptions] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openScheduleOptions, setOpenScheduleOptions] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openCreateFreeAccount, setOpenCreateFreeAccount] = useState(false);
  // const [openTutorial, setOpenTutorial] = useState(false);
  const avatars = SettingsProvider.getAvatars();
  const countrylang = SettingsProvider.getCountryLanguage();
  const Nickname = NicknameProvider.getNickname();
  const AccessCode = AccessCodeProvider.getAccessCode();
  const navigate = useNavigate();

  const handleSubmit = value => {
    NicknameProvider.setNickname(value);
    setMode("");
  };

  useEffect(() => {
    localStorage.removeItem("demo_openedmodal");

    if (!AccessCode) {
      navigate("/company");
    }

    if (!Nickname) {
      setMode("login");
    }

    if (!avatars)
      SettingsProvider.setAvatars({ user: "user0", chat: "chat-0" });
    if (!countrylang) {
      SettingsProvider.setCountryLanguage({
        country: "United States",
        language: "English (US)",
      });
    }

    const hash = location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(decodeURI(id));
      if (element)
        setTimeout(() => {
          const yCoordinate =
            element.getBoundingClientRect().top + window.pageYOffset;
          const yOffset = -100;
          window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
        }, 200);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname == "/benefits-and-programs" && !openBenefits) {
      {
        setOpenBenefits(true);
        setOpenArticles(false);
      }
    } else if (!openArticles && (location.pathname == "/articles" || article)) {
      setOpenBenefits(false);
      setOpenArticles(true);
      setFilter("")
    } else if (location.pathname == "/" && (openBenefits || openArticles)) {
      setOpenBenefits(false);
      setOpenArticles(false);
      setFilter("")
    }
  }, [location.pathname]);

  const handleTestClear = () => {
    AccessCodeProvider.removeAccessCode();
    logout(() => navigate("/company"));
  };

  function journeyCardClick(category) {
    setFilter(category);
    navigate("/benefits-and-programs")
  }

  useEffect(() => {
    // return () => {
    const withCache = localStorage.getItem("ctachat");
    if (withCache) {
      localStorage.removeItem("ctachat");
    }
    // };
  }, []);

  return (
    <div>
      {mode && (
        <Modal
          id={"login"}
          contentClass={cn(style.login_container)}
          isCloseButton={false}
          withClose={false}
        >
          {mode === "login" && (
            <Login
              onSubmit={handleSubmit}
              onRegister={() => setMode("register")}
            />
          )}
          {mode === "register" && (
            <Register
              onSubmit={handleSubmit}
              onLogin={() => setMode("login")}
            />
          )}
        </Modal>
      )}
      <Chatbot
        show={showChatbot}
        // show={true}
        onClose={() => setShowChatbot(false)}
      />
      <Header
        onOpenSettings={() => setOpenSettings(true)}
        onChatbot={() => setShowChatbot(true)}
        showHelp={() => setShowContact(true)}
        user={avatars.user}
        nickname={Nickname}
      />

      {/* {openTutorial && (
        <Tutorial
          onClose={() => setOpenTutorial(false)}
          openChat={() => {
            setOpenTutorial(false);
            setShowChatbot(true);
          }}
        />
      )} */}
      {openSettings && <Settings onClose={() => setOpenSettings(false)} />}
      {openCall && <ModalGethelp onClose={() => setOpenCall(false)} />}

      {/* on chat: step1 open options; step2 open chat modal/chat bot */}
      {openChatOptions && (
        <ModalOptions
          onClose={() => setOpenChatOptions(false)}
          data={chatOptions(
            () => {
              setOpenChatOptions(false);
              setShowChatbot(true);
            },
            () => {
              setOpenChatOptions(false);
              setOpenChat(true);
            }
          )}
        />
      )}
      {openChat && <ModalChat onClose={() => setOpenChat(false)} />}

      {/* schedule: step1 open options; step2 open modals/link */}
      {openScheduleOptions && (
        <ModalOptions
          onClose={() => setOpenScheduleOptions(false)}
          data={scheduleOptions(() => {
            setOpenScheduleOptions(false);
            setOpenSchedule(true);
          }, 
          data?.accesscode === 'Premium' ? 
          "https://welcometolaww.com/activate-counseling2-premium.php":
          () => {
            setOpenScheduleOptions(false);
            setOpenCreateFreeAccount(true);
          })        
        }
        />
      )}
      {openSchedule && <ModalSchedule onClose={() => setOpenSchedule(false)} />}
      {openCreateFreeAccount && (
        <ModalCreateFreeAccount onClose={() => setOpenCreateFreeAccount(false)} />
      )}

      {openBenefits ? (
        <BenefitsandPrograms
          onClick={() => navigate("/")}
          initialFilter={filter}
        />
      ) : openArticles ? (
        <Articles />
      ) : (
        <>
          <PageCenter
            id={"Home"}
            rootClass={cn(style.rootbanner)}
            contentClass={cn(style.banner)}
          >
            <div className={cn(style.text)}>
              <Heading>
                Start your personalized journey to emotional wellbeing
              </Heading>
              <Heading type={"h5"} className={cn(style.subtitle)}>
                Our digital platform is a globally accessible interactive
                platform, providing anonymous guidance to members regarding
                Optum’s emotional wellbeing benefits.
              </Heading>
              <div className={cn(style.bannerButtons)}>
                <Button
                  text={"Get guided help"}
                  onClick={() => setShowChatbot(true)}
                />
                <Button
                  light
                  text={"Show my benefits"}
                  onClick={() => navigate("/benefits-and-programs")}
                />
              </div>
            </div>
            <Journey />
          </PageCenter>
          <CTA
            call={() => setOpenCall(true)}
            chat={() => setOpenChatOptions(true)}
            schedule={() => setOpenScheduleOptions(true)}
          />
          <Spotlight onCreateAccount = {() => setOpenCreateFreeAccount(true)}/>
          <HealthJourneys onClick={journeyCardClick} />
          <ImportantCommunications />
          <FAQs />
        </>
      )}
      <Footer />
      <button
        onClick={handleTestClear}
        style={{ position: "absolute", bottom: 0 }}
      >
        Test Button - logout ({AccessCode})
      </button>
    </div>
  );
};

export default Page;
