export default [
  {
    id: "general-question-1",
    message:
      "May I ask you a series of questions so I can determine which care pathway is best for you?",
    trigger: "general-options",
  },
  {
    id: "general-options",
    options: [
      {
        value: 1,
        label: "Yes",
        trigger: "eval-1",
      },
      {
        value: 2,
        label: "Maybe later",
        trigger: "general-maybe-later",
      },
    ],
  },
  {
    id: "general-response-1",
    message:
      "People often report that they feel additional stress when engaging with the healthcare system",
    trigger: "general-question-2",
  },
  {
    id: "general-question-2",
    message:
      "Would you like some additional resources that can help with stress management?",
    trigger: "general-options-2",
  },
  {
    id: "general-options-2",
    options: [
      {
        value: 1,
        label: "Yes",
        trigger: "eval-1",
      },
      {
        value: 2,
        label: "No",
        trigger: ({ steps }) => {
          if (steps?.support && steps["support-options"]) {
            return "support-no";
          } else if (steps?.administrative && steps["administrative-options"]) {
            return "resources";
          }
        },
      },
    ],
  },
  {
    id: "general-question-3",
    message:
      "Would you like to answer a series of questions so my recommendations are more personalized?",
    trigger: "general-options",
  },
  {
    id: "general-question-4",
    message:
      "May I ask you a series of questions so I can determine which resources are the best for you?",
    trigger: "general-options",
  },
  {
    id: "general-maybe-later",
    message:
      "That’s okay you can always come back and take the assessment later.",
    trigger: "resources",
  },
  {
    id: "general-no",
    message: "Have a great rest of your day!",
    trigger: "resources",
  },
  {
    id: "resources",
    message: ({ steps }) => {
      if (
        steps &&
        ((steps["general-options"] && steps["general-options"].value == 1) ||
          (steps["general-options-2"] && steps["general-options-2"].value == 1))
      ) {
        return "Please wait while we retrieve the best recommended care pathways based on your feedback.";
      } else {
        if (steps?.administrative && steps["administrative-options"]) {
          switch (steps["administrative-options"].message) {
            case "Legal Guidance":
              return "That’s okay! I can still provide access to our most commonly used legal services available to you!";
              break;
            case "Financial Coaching":
              return "That's okay! I can still recommend some excellent financial coaching applications.";
              break;
          }
        } else if (steps?.support && steps["support-options"]) {
          return "Have a great rest of your day!";
        } else if (
          steps &&
          (steps["general-maybe-later"] || steps["general-no"])
        ) {
          if (steps["general-question-4"]) {
            return "In the meantime, here are some available benefits that may help.";
          } else {
            return "In the meantime, here are some available care pathways that you can check out!";
          }
        }
      }
    },
    trigger: ({ steps }) => {
      //Evaluation calculator
      function evalTotal(chatSteps) {
        const filteredKeys = Array(9)
          .fill()
          .map((_, i) => `eval-${i + 1}-options`);

        const questionList = Object.fromEntries(
          filteredKeys.map(k => [k, chatSteps[k].value])
        );

        const answerValues = Object.values(questionList).reduce(
          (a, b) => a + b,
          0
        );

        return answerValues;
      }

      function choices(stepOption, cardName) {
        if (steps[stepOption].value == 1) {
          const sum = evalTotal(steps);
          if (sum > 22) return `resources-cards-${cardName}-positive`;
          else return `resources-cards-${cardName}-negative`;
        } else return `resources-cards-${cardName}-later`;
      }

      if (steps?.wellbeing && steps["wellbeing-options"]) {
        switch (steps["wellbeing-options"].message) {
          case "Build resilience":
            break;
          case "Improve relationships":
            break;
          case "Feel less lonely or isolated":
            break;
          case "Feel happier in general":
            return choices("general-options", "wellbeing-happier");
            break;
          case "Feel less worried or anxious":
            break;

          default:
            return "resources-cards-wellbeing";
            break;
        }
      }

      if (steps?.lifestyle && steps["lifestyle-options"]) {
        switch (steps["lifestyle-options"].message) {
          case "Reduce drinking and/or drug use":
            break;
          case "Sleep better":
            break;
          case "Have more energy/focus":
            return choices("general-options", "lifestyle-energy");
            break;
          case "Work life balance":
            break;
          case "Parenting":
            break;

          default:
            return "resources-cards-wellbeing";
            break;
        }
        return "resources-cards-lifestyle";
      }

      if (steps?.pathways) {
        if (steps["pathways-options"].value == 1) {
          return "resources-cards-wellbeing-resilience";
        } else if (steps["pathways-options"].value <= 6) {
          return "resources-cards-wellbeing";
        }
        return "resources-cards-lifestyle";
      }

      if (steps?.administrative && steps["administrative-options"]) {
        switch (steps["administrative-options"].message) {
          case "Legal Guidance":
            return choices("general-options-2", "administrative-legal");
            break;
          case "Financial Coaching":
            return "resources-cards-assistance-financial";
            break;
          case "Learn about my benefits":
            break;
          default:
            return "resources-cards-assistance";
            break;
        }
      }

      if (steps?.support && steps["support-options"]) {
        switch (steps["support-options"].message) {
          case "Provider Search":
            return choices("general-options-2", "support-providersearch");
            break;
          case "Sleep better":
            break;
          case "Contact wellbeing specialist":
            break;
          default:
            break;
        }
      }
      if (steps["not-sure"]) {
        return "resources-cards-notsure";
      }

      return "resource-cards";
    },
  },
];
