import React from "react";
import {useResources} from "data/resources";
import chatBenefitsItems from "data/chatresources";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";

export const ResourceCard = ({
  className,
  title,
  description,
  onClick,
  smaller = false,
  link,
  modal,
}) => (
  <div
    className={cn(style.card, smaller && style.smaller, className)}
    onClick={link ? () => window.open(link) : () => onClick(modal)}
  >
    <Heading type={smaller ? "h5" : "h4"} withMargin>
      {title}
    </Heading>
    <p>{description}</p>
  </div>
);

const ResourceCards = ({ className, keys, onClick, link, smaller }) => {
  const benefitItems = useResources();
  const items = { ...benefitItems, ...chatBenefitsItems };

  return (
    <div className={cn(style.items)}>
      {keys.map((key, i) => (
        <ResourceCard
          key={i}
          {...items[key]}
          onClick={onClick}
          {...{ className, smaller }}
        />
      ))}
    </div>
  );
};

export default ResourceCards;
