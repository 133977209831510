import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue }) => {
  const handleContinue = () => {
    // onContinue();
  };

  return (
    <div className={cn(style.s10)}>
      <div className={cn(style.buttonBox)}>
        <div className={cn(style.icon)}>
          <Icon icon={"checkmark"} />
        </div>
        <div>
          <div className={cn(style.formSubtitle)}>
          Thanks for scheduling a 30-minute kickoff call. <br/>
          Your coach will call you:
          </div>
          <div className={cn(style.formText)}>
      
            <div
            className={cn(style.scheduledTime)}
            >Wednesday October 16th, 12:30pm ET</div>
          </div>
        </div>
      </div>
    </div>
  );
};
