import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

const STEPS = [
  {
    title: "Phone Call",
    text: "Your coach will call you at the phone number you provided at the time of your appointment.",
  },
  {
    title: "Video Session",
    text:
      "You can join your video session located in AbleTo’s mobile app or website up to 10 minutes ahead of session",
  },
];

export default ({ onContinue }) => {
  const handleContinue = () => {
    onContinue();
  };

  return (
    <div className={cn(style.s8)}>
      <div>
      This will be your preference for all upcoming appointments. You can always change your preference in Settings
      </div>
      {STEPS?.map((a, i) => {
        const { title, text } = a;
        return (
          <div
            className={cn(style.buttonBox)}
            onClick={handleContinue}
            key={title}
          >
            <div>
              <div className={cn(style.formSubtitle)}>{title}</div>
              <div className={cn(style.formText)}>{text}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
