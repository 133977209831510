import "./theme.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "theme/root.scss";

import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";

import { UIStatusProvider } from "provider/UIStatusProvider";
import { ThemeProvider } from "provider/ThemeProvider";

import BenefitsandPrograms from "screen/BenefitsandPrograms";
import Homepage from "screen/Homepage";
import Intro from "screen/Intro";
import Start from "screen/Start";
import Articles from "screen/Articles";
import ScrollTop from "atom/ScrollTop";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cn from "classnames";
import style from "theme/root.scss";

const router = createHashRouter([
  {
    path: "/benefits-and-programs",
    element: <Homepage />,
  },
  {
    path: "/company",
    element: <Intro />,
  },
  {
    path: "/articles",
    element: <Homepage />,
  },
  {
    path: "/articles/:article",
    element: <Homepage />,
  },
  {
    path: "/company/:company",
    element: <Start />,
  },
  {
    path: "/",
    element: <Homepage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <ThemeProvider className={cn(style.app)}>
    <UIStatusProvider>
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <ScrollTop />
        <RouterProvider router={router} />
      </div>
    </UIStatusProvider>
  </ThemeProvider>
);
