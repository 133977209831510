import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import {useResources} from "data/resources";
import { ResourceCard } from "molecule/ResourceCards";
import style from "./style.scss";
import cn from "classnames";

const data = {
  title: "Your benefits and programs",
  subtitle:
    "Emotional Wellbeing Solutions (EWS) provides care resources for emotional well-being based on your employer’s benefit plan. This includes self-care apps, on-demand consultations with EWS consultants, and virtual or in-person visits with clinicians in our network.",
  filterby: [
    "All",
    "Coaching",
    "Financial and Legal",
    "Self-Care",
    "Therapy",
    "Digital",
  ],
};

const BenefitsandPrograms = ({ initialFilter = "All" }) => {
  let location = useLocation().pathname;
  const [category, setCategory] = useState(initialFilter);

  const [filter, setFilter] = useState(null)
  // const [filterItems, setFilterItems] = useState([]);
  const items = useResources();

  function openResource(link) {
    window.open(link, "_blank");
  }

  const handleFilter = (val) => {
    setFilter(val);
  };


  const applyFilter = _list => {
    // refactor because we need to re-render by change of accesscode
    if (!filter || filter === 'All') return _list

    const filteredResult = _list.filter(item => {
      return item.category.indexOf(filter) >= 0;
    });

    return filteredResult;
  }

  const filterItems = applyFilter(_.values(items))

  return (
    <PageCenter>
      <div className={cn(style.title)}>
        <Heading type={"h2"}>{data.title}</Heading>
        <p>{data.subtitle}</p>
      </div>
      <div className={cn(style.content)}>
        <div className={cn(style.search)}>
          <h4>Filter by:</h4>
          {data.filterby.map((value, i) => (
            <div key={i} className={cn(style.radio)}>
              <input
                type="radio"
                id={"filterby" + i}
                name={"filterby" + i}
                value={value}
                checked={value == category || (!category && value == "All")}
                onChange={e => {
                  setCategory(e.target.value);
                  handleFilter(e.target.value);
                }}
              />
              <label htmlFor={"filterby" + i}>{value}</label>
            </div>
          ))}
        </div>
        <div className={cn(style.items)}>
          {filterItems?.map((value, i) => (
            <ResourceCard
              {...value}
              key={i}
              // onClick={() => openResource(value.link)}
              className={cn([style.card])}
            />
          ))}
        </div>
      </div>
    </PageCenter>
  );
};

export default BenefitsandPrograms;
