import React, { useState } from "react";
import cn from "classnames";
import iconEnum from "assets/icons";
import Button from "atom/Button";
import Input from "atom/Input";
import LoginBox from "molecule/LoginBox";
import LoginQuestions from "molecule/LoginQuestions";

import Form from "react-bootstrap/Form";

// styles
import style from "./style.scss";
import constants from "lib/constants";

const validate = ({ nickname }) => {
  const errors = [];
  if (nickname === "") {
    errors.push("Nickname doesn't exist");
  }

  return errors;
};

export default ({ onRegister, onSubmit }) => {
  const [nickname, setNickname] = useState("");
  const [validation, setValidation] = useState([]);

  const handleSubmit = e => {
    const errors = validate({ nickname });
    if (errors.length === 0) {
      // sign in will be no popups. so clear tutorial
      onSubmit(nickname);
    } else {
      setValidation(errors);
    }
  };

  const handleKeyDown = e => {
    if (e.code === "Enter") {
      handleSubmit();
    }
  };

  return (
    <LoginBox isFooter={false}>
      <div className={cn(style.login_header)}>
        <h2 className={cn(style.welcome_label)}>Sign In</h2>
        <p className={cn(style.nickname_label)}>Use your nickname to Sign in</p>
      </div>

      <div className={cn(style.login_content)}>
        <label>NICKNAME</label>
        <Input
          className={cn(style.nickname_input)}
          type="text"
          id="nickname"
          aria-describedby="nickname_err"
          value={nickname}
          maxLength={18}
          onChange={e => {
            // setValidation(validate({nickname: e.target.value}))
            setValidation([]);
            setNickname(e.target.value);
          }}
          placeholder="Enter your nickname"
          validation={validation}
          onKeyDown={handleKeyDown}
        />
        <div className={cn(style.login_button_wrapper)} aria-live="polite">
          <Button text="Sign In" onClick={handleSubmit} />
        </div>
        <div className={cn(style.create_nickname_wrapper)}>
          Don’t have an account?
          <a
            href="#"
            className={cn(style.create_nickname_link, "ms-1")}
            onClick={e => {
              e.preventDefault();
              onRegister();
            }}
          >
            Sign Up
          </a>
        </div>
        <LoginQuestions />
      </div>
    </LoginBox>
  );
};
