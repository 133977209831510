import React from "react";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import Card from "molecule/Card";
import style from "./style.scss";
import cn from "classnames";
import Anchor from "atom/Anchor";
import Icon from "atom/Icon";
import Button from "atom/Button";

const cards = [
  {
    title: "Flooding in Europe support and resources",
    link: "https://livewell.optum.com/cnt_alert_Flooding_in_Europe?accesstoken=9CE3CE60-7D8D-11EE-8A7F-CDB15810617E",
  },
  {
    title: "Unrest in the United Kingdom",
    link: "https://livewell.optum.com/cnt_alert_Unrest_in_the_United_Kingdom?accesstoken=9CE3CE60-7D8D-11EE-8A7F-CDB15810617E",
  },
  {
    title: "Middle East Conflict",
    link: "https://livewell.optum.com/cnt_alert_War_and_Conflict?accesstoken=9CE3CE60-7D8D-11EE-8A7F-CDB15810617E",
  },
  {
    title: "Tragedy in Russia — crisis support and resources",
    link: "https://livewell.optum.com/cnt_alert_Tragedy_in_Russia?accesstoken=9CE3CE60-7D8D-11EE-8A7F-CDB15810617E",
  },
  {
    title: "Russia/Ukraine conflict — crisis support and resources",
    link: "https://livewell.optum.com/cnt_alert_managing_trauma?accesstoken=9CE3CE60-7D8D-11EE-8A7F-CDB15810617E",  
  }
];

export default ({ onClick }) => (
  <PageCenter
    id={"ImportantCommunications"}
    rootClass={cn(style.root)}
    contentClass={cn(style.content)}
  >
    <Heading type="h2" centered>
      Important Communications
    </Heading>
    <div className={cn(style.cards)}>
      {cards.map(({
        title,
        link,
      }, i) => (
        <Anchor className={style.card} key={title} href={link}>
          <div>{title}</div>
          <div className={style.icon}>
            <Icon icon="cardarrow" />
          </div>
        </Anchor>
      ))}
    </div>
  </PageCenter>
);
