export default [
  {
    id: "administrative",
    message:
      "I have a number of resources that can help you with administrative issues!",
    trigger: "administrative-q1",
  },
  {
    id: "administrative-q1",
    message: "What type of support do you need?",
    trigger: "administrative-options",
  },
  {
    id: "administrative-options",
    options: [
      { value: 1, label: "Legal Guidance", trigger: "administrative-response" },
      {
        value: 2,
        label: "Financial Coaching",
        trigger: "administrative-response",
      },
      {
        value: 3,
        label: "Learn about my benefits",
        trigger: "administrative-response",
      },
      {
        value: 4,
        label: "I'm not sure or none of these",
        trigger: "administrative-response",
      },
    ],
  },
  {
    id: "administrative-response",
    message: ({ steps }) => {
      if (steps?.administrative && steps["administrative-options"]) {
        switch (steps["administrative-options"].message) {
          case "Legal Guidance":
            return "I see you're in need of some legal assistance and I've got the resources to help!";
            break;
          case "Financial Coaching":
            return "I see you're in need of some financial assistance and I've got the resources to help!";
            break;
          case "Learn about my benefits":
            return "Learning more about your options is a great place to start your wellness journey";
            break;
          default:
            return "That's okay! It can be hard  to know where to start when beginning your wellness journey!";
            break;
        }
      }
    },
    trigger: ({ steps }) => {
      if (steps?.administrative && steps["administrative-options"]) {
        switch (steps["administrative-options"].message) {
          case "I'm not sure or none of these":
            return "general-question-1";
            break;
          case "Learn about my benefits":
            return "general-no";
            break;
          default:
            return "administrative-response-2";
            break;
        }
      }
    },
  },
  {
    id: "administrative-response-2",
    message: ({ steps }) => {
      if (steps?.administrative && steps["administrative-options"]) {
        switch (steps["administrative-options"].message) {
          case "Legal Guidance":
            return "People report that they feel additional stress when addressing a legal issue";
            break;
          default:
            return "People report that they feel additional stress when addressing a financial issue";
            break;
        }
      }
    },
    trigger: "general-question-2",
  },
];
