import React, { useEffect, useState, useContext } from "react";
import _ from 'lodash'
import { useLocation, Link } from "react-router-dom";
import Arrow from "assets/icons/down-arrow.svg";
import { LinkButton } from "atom/Anchor";
import Button from "atom/Button";
import Icon from "atom/Icon";
import PageCenter from "atom/PageCenter";
import OptumLogo from "assets/optum-logo.svg";
import MenuIcon from "assets/icons/menu.svg";
import CloseIcon from "assets/icons/x.svg";
import ModalContact from "molecule/ModalContact";
import ModalGethelp from "molecule/ModalGethelp";
import PrimaryLinks from "../PrimaryLinks";
import TopAlert from "molecule/TopAlert";
import Sidebar from "../Sidebar";
import SelectFitContent from "atom/SelectFitContent";

import { ThemeContext } from "provider/ThemeProvider";

import style from "./style.scss";
import cn from "classnames";

const primaryLinks = [
  { label: "Home", url: "/#Home" },
  { label: "Health journeys", url: "/#Health journeys" },
  { label: "Articles", url: "/articles" },
  { label: "FAQs", url: "/#FAQs" },
];

const docksLinks = [
  { label: "Home", url: "/#Home" },
  { label: "Health journeys", url: "/#Health journeys" },
  { label: "Articles", url: "/articles" },
];

// LANGUAGE object list
const LANGUAGES = [
  { value: "en-US", label: "English (US)" },
  { value: "en-GB", label: "English (UK)" },
  { value: "ar", label: "Arabic" },
  { value: "zh-Hans", label: "Chinese (Simplified)" },
  { value: "zh-Hant", label: "Chinese (Traditional)" },
  { value: "nl", label: "Dutch" },
  { value: "fr-CA", label: "French (Canada)" },
  { value: "fr-FR", label: "French (France)" },
  { value: "de", label: "German" },
  { value: "he", label: "Hebrew" },
  { value: "it", label: "Italian" },
  { value: "ja", label: "Japanese" },
  { value: "ko", label: "Korean" },
  { value: "ms", label: "Malay" },
  { value: "nb", label: "Norwegian" },
  { value: "pl", label: "Polish" },
  { value: "pt-BR", label: "Portuguese (Brazil)" },
  { value: "ru", label: "Russian" },
  { value: "es", label: "Spanish" },
  { value: "sv", label: "Swedish" },
  { value: "th", label: "Thai" },
  { value: "tr", label: "Turkish" },
];

const RightSide = ({
  onOpenSettings,
  user = "user0",
  setShowContact,
  setShowHelp,
}) => (
  <div className={cn(style.sidebar)}>
    {/* <LinkButton
      className={cn(style.linkButton)}
      onClick={() => setShowContact(true)}
    >
      Help
    </LinkButton> */}
    <LinkButton className={cn(style.linkButton)} onClick={() => null}>
      Language <Arrow />
    </LinkButton>
    <LinkButton onClick={onOpenSettings} className={cn(style.linkButton)}>
      UnknownPerson <Arrow />
    </LinkButton>
    <div className={cn(style.items)}>
      24/7 emotional support&nbsp;&nbsp;
      <Button
        light={true}
        onClick={() => setShowHelp(true)}
        className={cn(style.button)}
        TagName="div"
      >
        <Icon icon={"phone"} /> Get help now
      </Button>
    </div>
  </div>
);

const Header = ({ onOpenSettings, onChatbot, user = "user0" }) => {
  let location = useLocation();
  const { data, setData } = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [onTop, setOnTop] = useState(true);

  const onLanguageChange = newLanguage => {
    setData(prev => {
      const _v = _.cloneDeep(prev);
      _v.language = newLanguage;
      return _v;
    })
  };

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY >= 500 && onTop) setOnTop(false);
      else if (window.scrollY <= 500 && !onTop) setOnTop(true);
    };

    return () => (window.onscroll = null);
  });

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <>
      <PageCenter
        rootClass={cn(style.root)}
        contentClass={cn(style.content)}
        id={"header"}
      >
        <Sidebar
          open={open}
          links={primaryLinks}
          other={
            <RightSide
              {...{ onOpenSettings, user, setShowContact, setShowHelp }}
            />
          }
        />
        <div className={cn(style.dock, !onTop && style.visible)}>
          <PrimaryLinks links={docksLinks} location={location} />
          <Button
            onClick={() => setShowHelp(true)}
            className={cn(style.start)}
            TagName="div"
          >
            {data?.contacts?.phone_getHelp}
          </Button>
          <Button onClick={onChatbot} className={cn(style.start)} TagName="div">
            Get guided help
          </Button>
          <div className={cn(style.divider)} />
          <div onClick={onOpenSettings} className={cn(style.settings)} />
        </div>
        <div className={cn(style.header)}>
          <div className={cn(style.menu)}>
            {open ? (
              <CloseIcon onClick={() => setOpen(false)} />
            ) : (
              <MenuIcon onClick={() => setOpen(true)} />
            )}
          </div>
          <div className={cn(style.logo)}>
            <Link to={"/"}>
              <OptumLogo />
            </Link>
          </div>
          <div className={cn(style.helplinks)}>
            {/* <LinkButton
              className={cn(style.linkButton)}
              onClick={() => setShowContact(true)}
              TagName="div"
            >
              Help
            </LinkButton> */}
            {/* <div className={cn(style.divider)} /> */}
            <SelectFitContent
              value={data?.language}
              onChange={e => onLanguageChange(e.target.value)}
              id={"language_select"}
              values={LANGUAGES}
              className={cn(style.select)}
            />
            <div className={cn(style.divider)} />
            <div onClick={onOpenSettings} className={cn(style.linkButton)}>
              UnknownPerson <Arrow />
            </div>
          </div>
        </div>
        <div className={cn(style.header_bottom)}>
          <PrimaryLinks links={primaryLinks} location={location} />
          <div className={cn(style.items)}>
            24/7 emotional support
            <Button
              light={true}
              onClick={() => setShowHelp(true)}
              className={cn(style.button)}
              TagName="div"
            >
              <Icon icon={"phone"} /> Get help now
            </Button>
          </div>
        </div>
      </PageCenter>

      <TopAlert />

      {showHelp && <ModalGethelp onClose={() => setShowHelp(false)} />}
      {showContact && <ModalContact onClose={() => setShowContact(false)} />}
    </>
  );
};

export default Header;
