import React, { useState } from "react";
import Button from "atom/Button";
import Modal from "atom/Modal";
import { Heading } from "atom/Typography";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import AbletoLogo from "assets/ableto-logo.svg";

import cn from "classnames";
import data from "./data.js";

import Sec_Step1 from "./Sec_Step1";
import Sec_Step2 from "./Sec_Step2";
import Sec_Step3 from "./Sec_Step3";
import Sec_Step4 from "./Sec_Step4";
import Sec_Step5 from "./Sec_Step5";
import Sec_Step6 from "./Sec_Step6";
import Sec_Step7 from "./Sec_Step7";
import Sec_Step8 from "./Sec_Step8";
import Sec_Step9 from "./Sec_Step9";
import Sec_Step10 from "./Sec_Step10";

const STEPS = [
  {
    modalTitle: "Choose the way you’d like to enroll",
    modalStyle: style.s1Modal,
  },
  {
    modalTitle: "Please enter your access code",
    modalStyle: style.s2Modal,
  },
  {
    modalTitle: "Your path to feeling better starts here",
    modalStyle: style.s3Modal,
  },
  {
    modalTitle: "Find the right care for you in less than 10 minutes",
    modalStyle: style.s4Modal,
  },
  {
    modalTitle: "Let’s start by learning a little bit about you",
    modalStyle: style.s5Modal,
  },
  {
    modalTitle: "Let’s get some basics",
    modalStyle: style.s6Modal,
  },
  {
    modalTitle: "Contact information",
    modalStyle: style.s7Modal,
  },
  {
    modalTitle: "What type of appointment would you prefer?",
    modalStyle: style.s8Modal,
  },
  {
    modalTitle:
      "What’s a good time to have a 30 minute kickoff call with your coach?",
    modalStyle: style.s9Modal,
  },
  {
    modalStyle: style.s10Modal,
  },
];

export default ({ onClose }) => {
  const [step, setStep] = useState(0);

  const handleContinue = () => {
    setStep(prev => prev + 1);
    // clear current focus
    document.activeElement.blur();
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
    document.activeElement.blur();
  };

  const handleSubmit = () => {
    console.log("submit");
    onClose();
  };

  return (
    <Modal
      id={"schedule"}
      contentClass={cn(style.content, STEPS[step]?.modalStyle)}
      {...{ onClose }}
    >
      <Heading type={"h3"} className={cn(style.heading)}>
        <AbletoLogo />
      </Heading>
      {STEPS[step]?.modalTitle ? (
        <h3 className={cn(style.modalTitle)}>{STEPS[step]?.modalTitle}</h3>
      ) : null}

      {step === 0 && <Sec_Step1 {...{ onContinue: handleContinue }} />}
      {step === 1 && <Sec_Step2 />}
      {step === 2 && <Sec_Step3 />}
      {step === 3 && <Sec_Step4 />}
      {step === 4 && <Sec_Step5 />}
      {step === 5 && <Sec_Step6 />}
      {step === 6 && <Sec_Step7 />}
      {step === 7 && <Sec_Step8 {...{ onContinue: handleContinue }} />}
      {step === 8 && <Sec_Step9 {...{ onContinue: handleContinue }} />}
      {step === 9 && <Sec_Step10 />}

      {/* first step doesnt have button */}
      {![0, 7].includes(step) && (
        <div className={cn(style.stepButtonContainer)}>
          {step > 0 && (
            <div className={cn(style.backButton)} onClick={handleBack}>
              <span>Back</span>
            </div>
          )}

          {step < STEPS.length - 1 && (
            <div className={cn(style.continueButton)}>
              <Button onClick={handleContinue} text={"Continue"} />
            </div>
          )}

          {step === STEPS.length - 1 && (
            <div className={cn(style.continueButton)}>
              <Button onClick={handleSubmit} text={"Done"} />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

// ============================== steps: ==============================
