import React, { useState, useContext } from "react";
import Button from "atom/Button";
import Modal from "atom/Modal";
import SelectCountry from "atom/SelectCountry";
import { Heading } from "atom/Typography";
import constants from "lib/constants";
import style from "./style.scss";
import cn from "classnames";
import {extractPhone} from "lib/utils";

import { ThemeContext } from "provider/ThemeProvider";

export default ({ onClose }) => {
  const { data } = useContext(ThemeContext);
  const [country, setCountry] = useState("");

  const handleCall = () => {
    onClose();
    window.location.href = `tel:${constants.NUMBER}`;
  };

  return (
    <Modal id={"help"} contentClass={cn(style.content)} {...{ onClose }}>
      <Heading type={"h5"}>Get Help Now</Heading>
      <p>Contact Your Emotional Wellbeing Specialist</p>
      <p>
        {
          "To speak with an Emotional Wellbeing Specialist, please contact the number(s) below."
        }
      </p>
      <label>Regional EWS Numbers</label>
      <ul>
        <li onClick={() => window.open(`tel:${extractPhone(data?.contacts?.phone_getHelp)}`)}>{data?.contacts?.phone_getHelp}</li>
      </ul>
      <p>
        {data?.contacts?.phone_text}
      </p>
      <label>Local Crisis / Emergency Numbers</label>
      <ul>
        <li onClick={() => window.open(`tel:${extractPhone(data?.contacts?.phone_crisis)}`)}>{data?.contacts?.phone_crisis}</li>
      </ul>
    </Modal>
  );
};
