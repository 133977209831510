import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";

// import react-bootstrap components
import {
  ButtonToolbar,
  OverlayTrigger,
  Popover,
  Button,
} from "react-bootstrap";

import style from "./style.scss";
import cn from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default ({
  renderButton = () => <Button>Click</Button>,
  id,
  children,
  ...props
}) => {
  const jsxOverlay = (
    <Popover id={id} {...props}>
      {children}
    </Popover>
  );

  return (
    <ButtonToolbar>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={jsxOverlay}
      >
        {renderButton()}
      </OverlayTrigger>
    </ButtonToolbar>
  );
};
