import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue }) => {
  const handleContinue = () => {
    onContinue();
  };

  return (
    <div className={cn(style.s5)}>
      <div className={cn(style.formContainer)}>
        Your answers will help us get you on the right path to feeling better.
        And because we follow HIPAA regulations, your responses are always
        secure.
      </div>
    </div>
  );
};
