import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue }) => {
  const handleContinue = () => {
    onContinue();
  };

  return (
    <div className={cn(style.s3)}>
      <div className={cn(style.formContainer)}>
        We’re glad you’re here, taking the first step. Together, we’ll explore
        your experiences and goals, and help you connect to care.
      </div>
    </div>
  );
};
