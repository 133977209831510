import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import Modal from "atom/Modal";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue }) => {
  const [showHelp, setShowHelp] = useState(false);
  const handleContinue = () => {
    onContinue();
  };

  return (
    <div className={cn(style.s2)}>
      <div className={cn(style.formContainer)}>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formSubtitle)}>Access code</div>
          <Form.Control type="text" />
          <div className={cn(style.formLink)} onClick={() => setShowHelp(true)}>
            Help me find my access code
          </div>
          {showHelp && (
            <Modal
              id={"help"}
              contentClass={cn(style.helpContent)}
              onClose={() => setShowHelp(false)}
            >
              <ModalHelp />
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

const ModalHelp = ({ onClose }) => {
  return (
    <div className={cn(style.s2)}>
      <h3 className={cn(style.modalTitle)}>How to find your access code</h3>

      <div className={cn(style.formText)}>
        Use the access code you received or were instructed to use from your
        health plan or employer.
        <br /><br />
        Still need help? Contact <a href="mailto:help@ableto.com">help@ableto.com</a>
      </div>
      <div className={cn(style.stepButtonContainer)}>
        <div className={cn(style.continueButton)}>
          <Button onClick={onClose} text={"Okay"} />
        </div>
      </div>
    </div>
  );
};
