import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue }) => {
  const handleContinue = () => {
    onContinue();
  };

  return (
    <div className={cn(style.s6)}>
      <div className={cn(style.formContainer, style.formSubRow)}>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formSubtitle)}>First name</div>
          <Form.Control type="text" />
        </div>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formSubtitle)}>Last name</div>
          <Form.Control type="text" />
        </div>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formSubtitle)}>Date of birth</div>
          <Form.Control type="text" placeholder="MM/DD/YYYY" />
        </div>
        {/* <div className={cn(style.formItem)}>
          <div className={cn(style.formSubtitle)}>Sex</div>
          <Form.Select>
              <option value="">Select</option>
            </Form.Select>
          <div className={cn(style.formLink)}>Why do we ask?</div>
        </div>       */}
      </div>
    </div>
  );
};
