import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

const STEPS = [
  {
    title: "Set up your account",
    text: "Create a profile and check your coverage",
  },
  {
    title: "Tell us how you’ve been feeling",
    text:
      "We'll explore your current mood and experiences to better understand your needs",
  },
  {
    title: "Unlock your program details",
    text:
      "Get a program recommendation based on your preferences and goals and schedule your first session",
  },
];

export default ({ onContinue }) => {
  const handleContinue = () => {
    // onContinue();
  };

  return (
    <div className={cn(style.s4)}>
      {STEPS?.map((a, i) => {
        const { title, text } = a;
        return (
          <div
            className={cn(style.buttonBox)}
            onClick={handleContinue}
            key={title}
          >
            <div>
              <div className={cn(style.icon)}>{i + 1}</div>
            </div>
            <div>
              <div className={cn(style.formSubtitle)}>{title}</div>
              <div className={cn(style.formText)}>{text}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
