import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue }) => {
  const handleContinue = () => {
    onContinue();
  };

  return (
    <div className={cn(style.s7)}>
      <div className={cn(style.formContainer, style.formSubRow)}>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formSubtitle)}>
            Email
            <small className={cn(style.formComment)}>
              We’ll send you important program information and reminders
            </small>
          </div>
          <Form.Control type="text" />
        </div>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formSubtitle)}>Phone number</div>
          <Form.Control type="text" placeholder="(000)-000-0000" />
        </div>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formSubtitle)}>Phone type</div>
          <Form.Select>
            <option value="mobile">Mobile</option>
            <option value="home">Home</option>
          </Form.Select>
        </div>

        <div className={cn(style.formItem)} style={{marginTop: '24px'}}>
          <div className={cn(style.formCheckbox)}>
            <Form.Check
              type="checkbox"
              label={
                <div>
                  I agree to AbleTo’s <a className={style.formLink}>Tems of Use</a> and <a className={style.formLink}>Privacy Policy</a>, and I
                  consent to receiving unencrypted emails.
                </div>
              }
            />
          </div>

          <div className={cn(style.formCheckbox)}>
            <Form.Check
              type="checkbox"
              label={
                <div>
                  I’ve read and agree to AbleTo’s <a className={style.formLink}>Data Use Authorization</a>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
