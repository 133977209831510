import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import optumLogo from "assets/images/Optum_Logo_ora_RGB.png";
import Button from "atom/Button";
import Modal from "atom/Modal";
import Popover from "atom/Popover";
import SelectCountry from "atom/SelectCountry";
import SelectLanguage from "atom/SelectLanguage";
import { Expandable } from "molecule/LoginQuestions";
import NicknameProvider from "provider/NicknameProvider";
import SettingsProvider from "provider/SettingsProvider";
import user_avatars from "assets/user_avatars";
import style from "./style.scss";
import cn from "classnames";

export default ({ onClose = () => {} }) => {
  const nickname = NicknameProvider.getNickname();
  const countrylang = SettingsProvider.getCountryLanguage();
  const currentAvatars = SettingsProvider.getAvatars();
  const [selectedCountrylang, setSelectedCountrylang] = useState([]);
  const [selectedAvatars, setSelectedAvatars] = useState([]);

  const [selectedUserAvatar, setSelectedUserAvatar] = useState(0);

  useEffect(() => {
    setSelectedCountrylang(countrylang);
    setSelectedAvatars(currentAvatars);
  }, []);

  const handleSubmit = e => {
    SettingsProvider.setCountryLanguage(selectedCountrylang);
    SettingsProvider.setAvatars(selectedAvatars);
    onClose();
  };

  const handleChange = (e, name) => {
    setSelectedCountrylang(prevState => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const handleChangeAvatars = index => {
    setSelectedUserAvatar(index);
  };

  return (
    <Modal id={"settings"} contentClass={cn(style.modal)} onClose={onClose}>
      <img className={cn(style.logo)} src={optumLogo} />
      {/* <div className={cn(style.intro)}>
        <h4>Your Profile </h4>
        <p>
          <strong>Welcome {nickname}! </strong>
          <br />
          Please feel free to update your settings as you like.
        </p>
      </div> */}

      <div className={cn(style.sectionContainer)}>
        <div className={cn(style.section)}>
          <div className={cn(style.sectionContent)}>
            <div className={cn(style.sectionTextContainer)}>
              <div className={cn(style.sectionTitle)}>Your Avatar</div>
              <div className={cn(style.sectionText)}>
                This is your avatar. Click on the avatar to select a new one
                from the list.
              </div>
            </div>
            <div className={cn(style.avatarSelect)}>
              <Popover
                renderButton={() => (
                  <div className={cn(style.avatar)}>
                    <img src={user_avatars[selectedUserAvatar]} />
                  </div>
                )}
                className={cn(style.avatarPopover)}
              >
                <div className={cn(style.avatarListContainer)}>
                  <div className={cn(style.avatarList)}>
                    {user_avatars.map((avatar, i) => (
                      <div
                        key={i}
                        className={cn(style.avatar)}
                        onClick={() => handleChangeAvatars(i)}
                      >
                        <img src={avatar} />
                      </div>
                    ))}
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className={cn(style.sectionFooter)}>
            <span>
              Updating the avatar is optional but strongly recommended.
            </span>
            <Button text="Save" onClick={() => handleSubmit()} />
          </div>
        </div>

        <div className={cn(style.section)}>
          <div className={cn(style.sectionContent)}>
            <div className={cn(style.sectionTextContainer)}>
              <div className={cn(style.sectionTitle)}>Your Username</div>
              <div className={cn(style.sectionText)}>
                This is your username used within Support Finder.
              </div>
              <div className={cn(style.sectionInput)}>
                <Form.Control type="text" value={nickname} disabled={true} />
              </div>
            </div>
          </div>
          <div className={cn(style.sectionFooter)}>
            <span>
              This <b>cannot</b> be recovered if forgotten, so please copy this
              value.
            </span>
          </div>
        </div>

        <div className={cn(style.section)}>
          <div className={cn(style.sectionContent)}>
            <div className={cn(style.sectionTextContainer)}>
              <div className={cn(style.sectionTitle)}>Share Link</div>
              <div className={cn(style.sectionText)}>
                Share this link with your dependents for quick and easy
                registration.
              </div>
              <div className={cn(style.sectionInput)}>
                <Form.Control
                  type="text"
                  value={
                    "http://supportfinder.optum.com/share/81263763-6588-45da-bdf5-8a80f3e49019"
                  }
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div className={cn(style.sectionFooter)}>
            <span>
              Links can only be shared 25 times before needing to regenerate.
            </span>
          </div>
        </div>

        <div className={cn(style.section)}>
          <div className={cn(style.sectionContent)}>
            <div className={cn(style.sectionTextContainer)}>
              <div className={cn(style.sectionTitle)} htmlFor="location">
                Your Region
              </div>
              <div className={cn(style.sectionText)}>
                Be sure to update your regional settings for products/services
                in your area.
              </div>
              <div className={cn(style.sectionInput)}>
                <SelectCountry
                  handleChange={e => handleChange(e, "country")}
                  selected={selectedCountrylang["country"]}
                />
              </div>
            </div>
          </div>
          <div className={cn(style.sectionFooter)}>
            <span>Available regions are determined by your company.</span>
            <Button text="Save" onClick={() => handleSubmit()} />
          </div>
        </div>

        <div className={cn(style.section)}>
          <div className={cn(style.sectionContent)}>
            <div className={cn(style.sectionTextContainer)}>
              <div className={cn(style.sectionTitle)} htmlFor="language">
                Your Language
              </div>
              <div className={cn(style.sectionText)}>
                We support 23 languages providing a seamless experience for
                members worldwide.
              </div>
              <div className={cn(style.sectionInput)}>
                <SelectLanguage
                  handleChange={e => handleChange(e, "language")}
                  selected={selectedCountrylang["language"]}
                />
              </div>
            </div>
          </div>
          <div className={cn(style.sectionFooter)}>
            <span>
              Updating your language will automatically reflect upon saving.
            </span>
            <Button text="Save" onClick={() => handleSubmit()} />
          </div>
        </div>
      </div>

      <div className={cn(style.questions)}>
        <h4>Questions?</h4>
        <div className={cn(style.items)}>
          <Expandable
            question={"What is a username?"}
            answer={
              "A username is a unique and secure anonymous name that you create for Optum's Support Finder. You can either choose your own username or have one auto generated."
            }
          />
          <Expandable
            question={"What if I forget my username?"}
            answer={
              "If you forget your username, you will need to create a new one. This is important for maintaining your privacy and security."
            }
          />
          <Expandable
            question={"Can I recover my old username later?"}
            answer={
              "No, unfortunately, old usernames cannot be retrieved or reset if forgotten. This is for security reasons. You will need to create a new username in such case."
            }
          />
          <Expandable
            question={"Is my information kept private?"}
            answer={
              "Yes, Support Finder maintains anonymity and does not capture or store personal member information. No data is shared with third parties for marketing or advertising purposes."
            }
          />
        </div>
      </div>
      {/* 
      <div className={cn(style.cancel)} onClick={onClose}>
        Close
      </div> */}
    </Modal>
  );
};
