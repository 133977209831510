export default [
  {
    id: "support",
    message:
      "I have a number of resources that can help you get immediate support!",
    trigger: "support-q1",
  },
  {
    id: "support-q1",
    message: "What type of support do you need?",
    trigger: "support-options",
  },
  {
    id: "support-options",
    options: [
      {
        value: 1,
        label: "Provider Search",
        trigger: "support-response",
      },
      {
        value: 2,
        label: "Digital authorization",
        trigger: "support-response",
      },
      {
        value: 3,
        label: "Contact wellbeing specialist",
        trigger: "support-response",
      },
      {
        value: 4,
        label: "Sign up for free coaching",
        trigger: "support-response",
      },
    ],
  },
  {
    id: "support-response",
    message: ({ steps }) => {
      if (steps?.support && steps["support-options"]) {
        switch (steps["support-options"].message) {
          case "Provider Search":
            return "I see you're interested in learning more about your provider options.";
            break;
          case "Sleep better":
            return "I see you're interested in obtaining a digital auth";
            break;
          case "Contact wellbeing specialist":
            return "I see you're interested in contacting a wellbeing specialist";
            break;
          default:
            return "I see you're interested in signing up for a free coaching";
            break;
        }
      }
    },
    trigger: "general-response-1",
  },
  {
    id: "support-no",
    message: ({ steps }) => {
      if (steps?.support && steps["support-options"]) {
        switch (steps["support-options"].message) {
          case "Provider Search":
            return "That’s okay! I will now connect you to the provider directory!";
            break;
          case "Sleep better":
            return "That’s okay! I will now send you to the digital auth platform";
            break;
          case "Contact wellbeing specialist":
            return "That’s okay!";
            break;
          default:
            return "That’s okay!";
            break;
        }
      }
    },
    trigger: "resources",
  },
];
